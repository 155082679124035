import { Fetcher } from "openapi-typescript-fetch";
import { paths } from "./generated-types";

const fetcher = Fetcher.for<paths>();

// global configuration
fetcher.configure({
  baseUrl: import.meta.env.VITE_APP_API_BASE_URI,
  init: {
    credentials: "include",
  },
});

export const csvDownloadPath = (applicationProcessId: string) =>
  `${import.meta.env.VITE_APP_API_BASE_URI}/v1/participationinapplicationprocess.read/export.xlsx?applicationProcessId=${applicationProcessId}`;

export const listApplicationProcessesOpenForEnrolment = fetcher
  .path("/v1/applicationprocess.read/listopenforenrolment")
  .method("get")
  .create();

export const register = fetcher
  .path("/v1/candidate.write/register")
  .method("post")
  .create();

export const login = fetcher
  .path("/v1/user.write/login")
  .method("post")
  .create();

export const getSelf = fetcher
  .path("/v1/user.read/self")
  .method("get")
  .create();

export const getCandidate = fetcher
  .path("/v1/candidate.read/get")
  .method("get")
  .create();

export const listCandidates = fetcher
  .path("/v1/candidate.read/list")
  .method("get")
  .create();

export const verifyEmail = fetcher
  .path("/v1/user.write/verifyemail")
  .method("post")
  .create();

export const enrolToApplicationProcess = fetcher
  .path("/v1/participationinapplicationprocess.write/enrol")
  .method("post")
  .create();

export const listParticipationInApplicationProcessByApplicationProcessIdAndFieldOfStudyId =
  fetcher
    .path(
      "/v1/participationinapplicationprocess.read/listbyapplicationprocessidandfieldofstudyid",
    )
    .method("get")
    .create();

export const listParticipationInApplicationProcessByCandidateId = fetcher
  .path("/v1/participationinapplicationprocess.read/listbycandidateid")
  .method("get")
  .create();

export const getApplicationProcess = fetcher
  .path("/v1/applicationprocess.read/get")
  .method("get")
  .create();

export const listApplicationProcessesByCommitteeMember = fetcher
  .path("/v1/applicationprocess.read/listbycommitteemember")
  .method("get")
  .create();

export const getDocumentUploadLink = fetcher
  .path("/v1/participationinapplicationprocess.read/getdocumentuploadlink")
  .method("get")
  .create();

export const getHomeworkUploadLink = fetcher
  .path("/v1/participationinapplicationprocess.read/gethomeworkuploadlink")
  .method("get")
  .create();

export const getEntranceExamUploadLink = fetcher
  .path("/v1/participationinapplicationprocess.read/getentranceexamuploadlink")
  .method("get")
  .create();

export const setEntranceExamResult = fetcher
  .path("/v1/participationinapplicationprocess.write/setentranceexamresult")
  .method("post")
  .create();

export const setEntranceExamAssessment = fetcher
  .path("/v1/participationinapplicationprocess.write/setentranceexamassessment")
  .method("post")
  .create();
