import {
  Button,
  Container,
  PasswordInput,
  Stack,
  TextInput,
  Title,
  Anchor,
  Group,
} from "@mantine/core";
import { IconLogin2 } from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, zodResolver } from "@mantine/form";
import { useMutation } from "@tanstack/react-query";
import { login } from "../openapi/api-client.ts";
import { z } from "zod";
import { useContext } from "react";
import { UserContext } from "../UserContext.tsx";
import { useForwardIfLoggedIn } from "../hooks/useForwardIfLoggedIn.ts";

// todo: disable page if logged in, forward to dashboard

const schema = z.object({
  email: z.string().email({
    message:
      "Bitte trag deine E-Mail-Adresse ein, die du zur Registrierung genutzt hast",
  }),
  password: z.string().min(6, {
    message:
      "Bitte trag dein Passwort ein, das du bei der Registrierung angegeben hast",
  }),
});

export const Login = () => {
  useForwardIfLoggedIn("/bewerbung");

  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const loginMutation = useMutation({
    mutationFn: login,
  });

  const form = useForm<{ email: string; password: string }>({
    initialValues: {
      email: "",
      password: "",
    },
    validate: zodResolver(schema),
    validateInputOnBlur: true,
    transformValues: (values) => {
      return {
        ...values,
      };
    },
  });

  const submit = form.onSubmit(async (values) => {
    loginMutation.mutate(
      {
        email: values.email,
        password: values.password,
      },
      {
        onError: () => {
          form.setErrors({
            email: "Falsche Zugangsdaten",
            password: "Falsche Zugangsdaten",
          });
        },
        onSuccess: (response) => {
          userContext.setUserData(response.data.userId, response.data.roles);

          if (response.data.roles.includes("candidate")) {
            navigate("/bewerbung");
            return;
          }

          if (response.data.roles.includes("committee")) {
            navigate("/kommission");
            return;
          }

          navigate("/");
        },
      },
    );
  });

  return (
    <Container my="lg">
      <Title mb="lg">Anmelden</Title>

      <form onSubmit={submit}>
        <Stack
          w={{
            md: "50%",
          }}
        >
          <TextInput label="E-Mail-Adresse" {...form.getInputProps("email")} />
          <PasswordInput label="Passwort" {...form.getInputProps("password")} />
          <Group gap="sm" mt="lg">
            <Button
              leftSection={<IconLogin2 />}
              color="blue"
              type="submit"
              size="lg"
              loading={loginMutation.isPending}
            >
              Login
            </Button>
            <Anchor component={Link} to="/registrieren">
              Registrieren
            </Anchor>
          </Group>
        </Stack>
      </form>
    </Container>
  );
};

export default Login;
